// Imports
import React from 'react';
import axios from 'axios';
import { makeStyles,withStyles  } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

//axios.defaults.baseURL = 'https://api.up-loyalty.com';

const useStyles = (muiBaseTheme) => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  media: {
    paddingTop: "56.25%"
  },
  content: {
    textAlign: "left",
    padding: muiBaseTheme.spacing.unit * 3
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 3}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.8
  },
  avatar: {
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      marginLeft: -muiBaseTheme.spacing.unit
    }
  }
});

class Home extends React.Component {
  state = {
    
}

constructor(props) {
    super(props);
    this.state = {
        loading: false,
    };
}
  componentDidMount() {
    axios.get('/services/v2/users/2/campaigns', {
      headers: {
        'Accept': 'application/json',
        'Authorization-Token':'2gTy4BaplO0s02n5VX_ceyTres934XzUpSlovakya',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
  }).then((res) => {
      this.setState({ campaigns: res.data });
  });
  }
  render(){
    const { classes } = this.props;
  return (
    <div>
      {this.state.campaigns != null && 
      this.state.campaigns.map((campaign, i) => {
        return(
        <Card key={i} className={classes.card}>
          <CardMedia
            className={classes.media}
            image={ 
              campaign.bannerUrl
            }
          />
          <CardContent className={classes.content}>
            <Typography
              className={"MuiTypography--heading"}
              variant={"h6"}
              gutterBottom
            >
              {campaign.title}
            </Typography>
            <Typography
              className={"MuiTypography--subheading"}
              variant={"caption"}
            >
              {campaign.terms}
            </Typography>
            {/* <CardContent>
              <Typography variant="body2" color="textSecondary" component="span">
                Win : {campaign.campaignRewardsModel.filter(x => x.rewardType.id === 1)[0] !== undefined ? '€' : '%'}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="span">
                dsa
              </Typography>
            </CardContent> */}
            <Divider className={classes.divider} light />
          </CardContent>
        </Card>
        )
      })
      
      }
    </div>
  )
}
}

export default withStyles(useStyles)(Home);